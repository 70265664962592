html {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  color: #1a1a1a;
}
header {
  min-height: 160px;
  background-color: tan;
  margin-bottom: 20px;
  padding: 20px 20px 0px 20px;
}
header h1 {
  font-size: 3em;
  text-align: center;
}
header h2 {
  font-size:2em;
  text-align: center;
}

header nav.navbar {
  background-color: tan;
}
header nav .nav-link {
  font-size: 1.25em;
}
header nav form .form-control {
  border:solid 1px #333;
}

section {
  margin-bottom: 20px;
  padding:20px;
}

body {
  background-color: rgb(235, 210, 179);
}

div.myred {
  color: blue;
}

pre.myred {
  color: red;
}

.strike {
  text-decoration: line-through;
}

.largerer {
  font-size: 60px;
}

.italic {
  font-style: italic;
}